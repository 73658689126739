import React, { useState } from 'react';
import Draggable from 'react-draggable';

import TemplateDefault from '../templates/default';

export default function TestPage() {
  const [dragging, setDragging] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [dropped, setDropped] = useState(false);

  const handleStart = () => {
    setDragging(true);
    setDropped(false);
  };
  const handleStop = () => {
    setDragging(false);
    if (hovering) {
      setDropped(true);
    }
    setHovering(false);
  };
  const handleMouseEnter = () => {
    if (dragging) {
      setHovering(true);
    }
  };
  const handleMouseLeave = () => {
    setHovering(false);
  };

  return (
    <TemplateDefault>
      <div className="tw-grid tw-grid-cols-2">
        <div>"Pulling out" example</div>
        <div>"Directing" example</div>
        <div className="tw-relative tw-h-[500px]">
          <div className="tw-absolute tw-left-[50px] tw-top-[100px] tw-flex tw-h-48 tw-w-48 tw-items-end tw-justify-center tw-bg-red-600">
            A
          </div>

          <Draggable axis="y" bounds={{ top: 0, bottom: 92 }}>
            <div className="tw-absolute tw-left-[50px] tw-top-[200px] tw-flex tw-h-48 tw-w-48 tw-cursor-pointer tw-items-end tw-justify-center tw-bg-green-600">
              B
            </div>
          </Draggable>
        </div>

        <div className="tw-relative tw-h-[500px]">
          <div className="tw-absolute tw-left-[50px] tw-top-[100px] tw-flex tw-h-48 tw-w-48 tw-items-end tw-justify-center tw-bg-red-600">
            A
          </div>

          <div
            className={`tw-absolute tw-right-[50px] tw-top-[100px] tw-flex tw-h-48 tw-w-48 tw-items-end tw-justify-center tw-bg-blue-600 ${
              hovering && 'tw-ring-4 tw-ring-yellow-400'
            } ${dropped && 'tw-border-4 tw-border-black'}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            C
          </div>

          <ul>
            {dragging && <li>Dragging</li>}
            {hovering && <li>Hovering</li>}
            {dropped && <li>Dropped</li>}
          </ul>

          <Draggable onStart={handleStart} onStop={handleStop} bounds="parent">
            <div
              className={`tw-absolute tw-left-[100px] tw-top-[200px] tw-flex tw-h-8 tw-w-8 tw-cursor-pointer tw-items-end tw-justify-center tw-bg-green-600 ${
                dragging && 'tw-pointer-events-none'
              }`}
            >
              B
            </div>
          </Draggable>
        </div>
      </div>
    </TemplateDefault>
  );
}
